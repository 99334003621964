.faq-v2 {
	margin-top: var(--page-margin-top);

	@media (max-width:1200px) {
		margin-top: 62px;
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

		a {
			text-decoration: underline !important;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	p {
		/* color: var(--color-primary); */
	}
}