.fur-eigen-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width:1200px) {
		margin-top: 62px;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner1-1980.jpeg);
		background-position: center bottom;
	}

	.widget-side-bl {
		.widget-block {
			.btn {
				display: inline-block;
			}
		}
	}

}

.row-reverse-mob {
	@media (max-width: 767px) {
		flex-direction: column-reverse;
	}
}



.link {
	text-decoration: underline;
	font-size: 14px;
}

.reisever-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width:1200px) {
		margin-top: 62px;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner2-1980.jpeg);
		background-position: center;
	}

}

.faq-v2 {
	margin-top: var(--page-margin-top);

	@media (max-width:1200px) {
		margin-top: 62px;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner-faq.jpeg);
		background-position: center bottom;
	}
}

.widget-side-bl {
	.btn {
		margin-left: 36px;
	}

	.widget-header {
		font-size: 1.25rem;
	}

	.icon-bl {
		margin-bottom: 18px;
		position: relative;
		padding-left: 36px;
		line-height: 24px;

		img {
			position: absolute;
			left: 0;
			top: 4px;

			padding: 0 18px 0 0;
			width: 38px;
			height: auto;
		}
	}
}

.img-row-tbl {
	@media (max-width:550px) {
		border-top: 1px solid #dee2e6 !important;
	}

	tr {
		td {
			@media (max-width:550px) {
				text-align: center !important;
			}

			&:last-child {
				@media (max-width:550px) {
					border-bottom: 1px solid #dee2e6 !important;
				}
			}
		}
	}

	td {
		@media (max-width:550px) {
			display: block;
			width: 100%;
			border: none !important;
		}

		&.table-img {
			@media (max-width:550px) {
				text-align: center !important;
			}
		}

		&.hide-row-mob {
			@media (max-width:550px) {
				display: none;
			}
		}
	}
}

.zahlungsmoeglichkeiten-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-zahlung.jpeg);
		background-position: center bottom;
	}
}

.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/contact-banner-1.jpg);
		background-position: center;
	}
}