.themes-v2 {
	/*	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: minmax(150px, auto);
	grid-gap: 1.6em;
	grid-template-areas:
		'img1 img1 img2 img2'
		'img1 img1 img2 img2';*/
	display: flex;
	align-items: center;
	justify-content: center;

	.themetile-col {
		width: 430px;
		height: 330px;
		margin: 0 15px;

		@media (max-width: 1500px) {
			width: 352px;
			height: 325px;
		}

		@media (max-width: 1199px) {
			width: 292px;
			height: 325px;
		}

		@media (max-width: 991px) {
			width: 100%;
			height: 340px;
			margin: 0 0 100px;
		}

		@media (max-width: 767px) {
			width: 100%;
			height: 340px;
		}

		a {
			height: 100%;
		}

		&:last-child {
			@media (max-width: 991px) {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/Strandhaus_img.webp);
		position: relative;

		@media (max-width: 992px) {
			margin-bottom: 80px;
		}

		&:before {
			content: "";
			background: url(RESOURCE/img/strandhaus_logo.webp) no-repeat 0 0;
			position: absolute;
			top: -110px;
			left: 0;
			width: 323px;
			height: 130px;
			right: 0;
			margin: 0 auto;

			@media (max-width: 992px) {
				top: -95px;
				width: 270px;
				height: 110px;
				background-size: contain;
			}
		}
	}

	.img2 {
		position: relative;
		grid-area: img2;
		background-image: url(RESOURCE/img/Meerhaus_img.webp);

		&:before {
			content: "";
			background: url(RESOURCE/img/meerhaus_logo.webp) no-repeat 0 0;
			position: absolute;
			top: -100px;
			left: 0;
			width: 290px;
			height: 130px;
			right: 0;
			margin: 0 auto;

			@media (max-width: 992px) {
				top: -73px;
				width: 200px;
				height: 93px;
				background-size: contain;
			}
		}
	}

	.img3 {
		grid-area: img3;
		display: none !important;
		/*background-image: url(RESOURCE/img/theme3.jpeg);*/
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/grid-segeln.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/grid-luftbild.jpg);
	}

	.img6 {
		grid-area: img6;
		background-image: url(RESOURCE/img/theme-offers.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;

		@media (max-width: 992px) {
			height: 100%;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;

	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}