.contact-sidebar-v1 {
	background-color: var(--contact-sidebar-bg-color);

	.contact-map {
		width: 100%;
		height: 300px;
	}

	.contact-bl {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		padding-left: 40px;
		min-height: auto;
		align-items: center;
		font-size: var(--font-size-md);

		&:last-child {
			margin-bottom: 0;
		}

		&.email-bl {
			img {
				top: 3px;
			}
		}

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-primary);
			}
		}


		i,
		img {
			color: var(--color-white);
			/* background-color: var(--color-primary); */
			/* padding: 5px; */
			width: 22px;
			height: auto;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			/* box-shadow: 0 0 10px rgba(0, 0, 0, .18); */
			font-size: 16px;
		}


	}

	.openings {
		div {
			margin-top: 10px;
			font-size: var(--font-size-md);

		}
	}
}