.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	@media (max-width:1200px) {
		margin-top: 62px;
	}

	@media all and (min-width: 768px) and (max-width: 1199px) {
		margin-top: 80px;
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.inner-banner {

		&:before {
			@media (max-width:767px) {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: rgba(0, 0, 0, .2);
			}



		}

		.banner-text {
			margin-top: 10px;
			position: relative;
			padding: 15px;
			display: inline-block;
			text-align: left;

			p {
				margin-bottom: 0;
			}
		}

		&.seaview {
			background-image: url(RESOURCE/img/banner-seaview.jpeg);
		}

		&.pets {
			background-image: url(RESOURCE/img/banner-hund.jpeg);
			background-position: center top;
		}

		&.family {
			background-image: url(RESOURCE/img/banner-family.jpeg);
		}

		&.unit {
			background-image: url(RESOURCE/img/banner-unit1.jpeg);
		}

		&.kuzurlab {
			background-image: url(RESOURCE/img/banner-kurzurlaub.jpeg);
		}

		&.offers {
			background-image: url(RESOURCE/img/banner-angebot.jpeg);
		}

		.container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			height: 100%;
			color: #fff;

			h1 {
				color: #fff;

				@media (max-width:767px) {
					font-size: 24px;
				}
			}
		}
	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 6px 8px 6px 0;
	padding: 2px 6px;
	display: inline-block;
	border-radius: 4px;
	background-color: var(--color-white);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;


	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {

		background-color: var(--color-grey-light);


		.close {
			opacity: 1;
		}
	}
}

.search-tocken {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 2px 5px;
	border: 1px solid var(--color-primary);
	margin-bottom: 14px;

	.search-sort-map {
		display: inline-flex;
		align-items: center;

		@media (max-width: 420px) {
			flex-wrap: wrap;
		}

		.map-button {
			@media (max-width: 992px) {
				width: 50%;
				padding-right: 5px;
			}

			@media (max-width: 420px) {
				width: 100%;
				padding-right: 0px;
				padding-bottom: 5px;
			}

			.btn.btn-secondary {
				@media (max-width: 992px) {
					width: 100%;
				}
			}
		}

		.sort-by {
			@media (max-width: 992px) {
				width: 50%;
				padding-left: 5px;
			}

			@media (max-width: 420px) {
				width: 100%;
				padding-left: 0px;
				padding-bottom: 0;
			}



			.sorting-trigger {
				@media (max-width: 992px) {
					width: 100%;
					margin-left: 0 !important;
				}
			}
		}


		@media (max-width: 992px) {
			width: 100%;
			justify-content: space-between;
			margin-top: 15px;
			margin-bottom: 5px;
		}


		.map-button {
			.btn {
				&:before {
					content: "\f279";
					margin-right: 5px;
					font-family: "Font Awesome 5 Free";
				}
			}
		}
	}

	.sort-rest {
		span {
			color: var(--color-primary);
		}
	}
}