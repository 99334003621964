.map-view {
	.search-pagination {
		display: none;
	}
}




.search-token {
	.mysearch {
		.my-search-token {
			border-radius: 8px;
			backhround-color: var(--color-white);
			font-size: var(--font-size-md);

			.close {
				font-weight: 400px;
				margin-left: 5px;
				font-size: 22px;
			}
		}
	}
}