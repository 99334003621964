.footer-v1 {

	.show-mob {
		display: none;

		@media (max-width:550px) {
			display: block;
		}
	}

	.hide-mob {
		display: block;

		@media (max-width:550px) {
			display: none;
		}
	}

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		.first-col {
			display: flex !important;
			flex-direction: column;
			justify-content: center;

			&.hide-mob {
				@media (max-width:550px) {
					display: none !important;
				}
			}
		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);

			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 100%;
			height: auto;
			margin-top: 0;
			margin-top: 30px;
			max-width: 175px;
		}

		.social-icons {
			text-align: center;
			max-width: 160px;
			margin-top: 35px !important;

			@media (max-width:550px) {
				text-align: center;
				max-width: 100%;
			}

			li {
				margin: 0 7px !important;

				img {
					width: auto;
					height: 26px;
				}
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.sec-col {
			.contact-list {

				li {
					margin-bottom: 18px;
					position: relative;
					padding-left: 36px;
					line-height: 24px;

					@media (max-width:550px) {
						padding-top: 10px;
						padding-bottom: 10px;
						border: 1px solid #fff;
						padding-right: 10px;
						padding-left: 46px;
					}

					i,
					img {
						position: absolute;
						left: 0;
						top: 4px;
						color: #fff;

						padding: 0 18px 0 0;
						width: 38px;
						height: auto;

						@media (max-width:550px) {
							left: 10px;
							top: 11px;
						}
					}
				}
			}

			.social-icons {
				li {
					margin-right: 15px;
				}
			}
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -70px;
			padding-right: 15px;

			@media (max-width: 767px) {
				margin-left: 10px;
				margin-top: 0px;
				text-align: left;
			}

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: #03313c;
		color: var(--footer-copyright-font-color);

		.row {
			align-items: center;
		}

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			padding-right: 20px;
			display: flex;
			align-items: center;



			@media (max-width: 992px) {
				padding-right: 0;
				margin: 0;

			}

			img {
				width: 50px;
			}

		}

		.right-box {
			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: flex-end;
				margin: 0 45px 0 0;

				@media (max-width: 992px) {
					justify-content: flex-start;
					margin-right: 50px;
					margin-bottom: 15px;
				}


				li {
					margin: 0 0 0 15px;
					position: relative;
					padding: 0 0 0 15px;
					line-height: 1;

					@media (max-width: 992px) {
						margin-top: 5px;
						margin-bottom: 5px;
					}

					&:first-child {
						margin-left: 0;
						padding-left: 0;

						&:before {
							display: none;
						}
					}

					&.left-bdr {
						@media (max-width: 550px) {
							padding: 0;
							margin: 0;
						}

						@media (max-width: 550px) {
							&:before {
								display: none;

							}
						}
					}

					&:before {
						display: block;
						content: " ";
						position: absolute;
						left: 0;
						width: 1px;
						height: 15px;
						background: #fff;
						top: 1px;


					}

					a {}
				}
			}
		}
	}

}